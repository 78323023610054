
@import "../../../libs/select2.less";
body, html {
    height: 100%;
    background:  @sixteenternary-color;
}

.nav-top {
    user-select: none;
    position: fixed;
    z-index: 11;
    width: 100%;
    left: 0;
    top: 0;  
    background: @seventernary-color;

	.logo {
		.inline-block;
		padding: 1.5rem 0;
		padding-bottom: 1rem;

		img {
			max-width: 180px;
		}

		@media(max-width: 580px) {
			display: block;
			text-align: center;
		}
	}

	ul {
		.reset-list;

		li {
			.inline-block;
			position: relative;
			margin: 0 -2.1px;
			list-style: none;
			border-left: 1px solid darken(@tenternary-color, 65);

			&:last-child {
				border-right: 1px solid darken(@tenternary-color, 65);
			}

			a,
			button {
				display: block;
				width: 100%;
				padding: 1.5rem;
				border: 0;
				background: transparent;
				color: @terceary-color;
				font-size: 2rem;

				&:hover {
					background: darken(@tenternary-color, 65);
					text-decoration: none;
				}
			}
		}

		@media(max-width: 580px) {
			display: table;
			width: 100%;

			li {
				display: table-cell;
				text-align: center;
				border-top: 1px solid darken(@primary-color, 3);
			}
		}
	}
}


.wrapper {
	position: relative;
	min-height: 95%;
	padding-top: 61px;
	background: @sixteenternary-color;

	.sidebar {
		position: fixed;
	    background-color: @terceary-color;
	    width: 270px;
	    height: ~'calc(100% - 70px)';
	    top: 61px;
	    left: -100%;
	    transition: all;
	    -webkit-transition-duration: .3s;
	    transition-duration: .3s;
	    z-index: 10;
	    overflow-y: auto;
	    padding-bottom: 4rem;
	    box-shadow: 0 1px 1px rgba(0,0,0,.2);
	    user-select: none;

	    &::-webkit-scrollbar {
		    width: 12px;
		}
		 
		&::-webkit-scrollbar-track {
		    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
		}
		 
		&::-webkit-scrollbar-thumb {
		    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
		    background: @terceary-color; 
		}

	    & > ul {
	    	.reset-list;
	    	padding-bottom: 4rem;

	    	& > li {
	    		cursor: pointer;
	    		border-bottom: 1px solid fade(@sixternary-color, 15);
	    		border-left: 4px solid transparent;

	    		&:first-child {
	    			border-top: 1px solid fade(@sixternary-color, 15);
	    		}

	    		& > a {
	    			position: relative;
	    			display: block;
	    			padding: 1.3rem 1.5rem;
	    			color: fade(@sixternary-color, 98);
	    			font-size: 1.7rem;
	    			text-transform: uppercase;
	    			font-weight: 500;

	    			i {
	    				margin-right: 1rem;
	    				font-size: 2rem;
	    				color: @primary-color;
	    			}

	    			&:hover {
	    				text-decoration: none;
	    			}
	    		}

	    		&.sub-menu {
	    			
	    			& > a:before {
					    content: "\f0d9";
					    -webkit-transform: scale(1);
					    -ms-transform: scale(1);
					    -o-transform: scale(1);
					    transform: scale(1);
					}

					& > a:after {
					    content: "\f0d8";
					    transform: scale(0);
					}

					& > a:after,
					& > a:before {
					    position: absolute;
					    top: 50%;
					    margin-top: -11px;
					    font-family: 'FontAwesome';
					    font-size: 17px;
					    right: 15px;
					    -webkit-transition: all;
					    -o-transition: all;
					    transition: all;
					    -webkit-transition-duration: 250ms;
					    transition-duration: 250ms;
					}

					a {
						i {
							color: @primary-color;
						}
					}
	    		}	    		

	    		&.active,
	    		&:hover {
	    			border-left: 4px solid @eightternary-color;

	    			& > a {
	    				background-color: rgba(240, 240, 240, 0.5);
	    			}
	    		}

	    		&.active {
	    			&.sub-menu > a:before {
					    content: "\f0d7";
					    -webkit-transform: scale(0);
					    -ms-transform: scale(0);
					    -o-transform: scale(0);
					    transform: scale(0);
					}

					&.sub-menu > a:after {
					    content: "\f0d7";
					    -webkit-transform: scale(1);
					    -ms-transform: scale(1);
					    -o-transform: scale(1);
					    transform: scale(1);
					}

					& > ul {
						display: block;
					}
	    		}

	    		& > ul {
	    			.reset-list;
	    			display: none;
	    			margin-left: 2rem;

	    			& > li {
	    				list-style: none;
	    				margin: .8rem 0;

	    				& > a {
	    					display: block;
	    					color: fade(@sixternary-color, 65);
	    					font-size: 1.8rem;
	    					font-weight: 500;

	    					i {
	    						margin-right: .5rem;
	    						font-size: 1.9rem;
	    					}

	    					&:hover {
	    						text-decoration: none;
	    						color: fade(@sixternary-color, 95);

	    						i {
	    							color: @secondary-color;
	    						}
	    					}
	    				}
	    			}
	    		}
	    	}
	    } 

	    .profile {
	    	padding: 2rem 1rem;
	    	background: fade(@sixteenternary-color, 50);

	    	.name {
	    		.reset-list;
	    		font-size: 1.8rem;
	    		font-weight: 500;
	    		color: darken(@primary-color, 10);
	    	}

	    	.text {
	    		font-size: 1.6rem;
	    	}

	    	figure {
	    		margin-bottom: 1rem;
	    		img {
	    			border-radius: 50%;
	    			max-width: 120px;
	    			margin: 0 auto;
	    		}
	    	}
	    }

	    @media(max-width: 580px) {
			top: 120px;
		}
	}

	@media(max-width: 580px) {
		padding-top: 120px;
	}
}


.breadcrumb {
	.reset-list;	
	margin: 2rem 0;
    padding: .8rem 1rem;
    background: @terceary-color;
    box-shadow: 0 0px 1px rgba(0,0,0,.1);

	li {
		.inline-block;
		list-style: none;

		a {
			color: @primary-color;

			i {
				color: @primary-color;
				margin-left: .5rem;
			}

			&:hover {
				text-decoration: none;
			}
		}
	}

	&.breadcrumb-no-margin-bottom {
		margin-bottom: 0;
	}
}

body.navIsActive {
	overflow-x: hidden;
	min-width: 500px;
	padding-left: 270px;

	.wrapper {
		min-width: 500px;
	}

	.sidebar {
		z-index: 100;		
		left:0px;
	}
}
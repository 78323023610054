@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/hinted-MerriweatherSans-Regular.eot');
    src: local('Merriweather Sans'), local('MerriweatherSans-Regular'),
        url('fonts/hinted-MerriweatherSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-MerriweatherSans-Regular.woff') format('woff'),
        url('fonts/hinted-MerriweatherSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/hinted-MerriweatherSans-BoldItalic.eot');
    src: local('Merriweather Sans Bold Italic'), local('MerriweatherSans-BoldItalic'),
        url('fonts/hinted-MerriweatherSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-MerriweatherSans-BoldItalic.woff') format('woff'),
        url('fonts/hinted-MerriweatherSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: url('fonts/hinted-WorkSans-Medium.eot');
    src: local('Work Sans Medium'), local('WorkSans-Medium'),
        url('fonts/hinted-WorkSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-WorkSans-Medium.woff') format('woff'),
        url('fonts/hinted-WorkSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/hinted-MerriweatherSans-Italic.eot');
    src: local('Merriweather Sans Italic'), local('MerriweatherSans-Italic'),
        url('fonts/hinted-MerriweatherSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-MerriweatherSans-Italic.woff') format('woff'),
        url('fonts/hinted-MerriweatherSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: url('fonts/hinted-WorkSans-Black.eot');
    src: local('Work Sans Black'), local('WorkSans-Black'),
        url('fonts/hinted-WorkSans-Black.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-WorkSans-Black.woff') format('woff'),
        url('fonts/hinted-WorkSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/hinted-MerriweatherSans-Bold.eot');
    src: local('Merriweather Sans Bold'), local('MerriweatherSans-Bold'),
        url('fonts/hinted-MerriweatherSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-MerriweatherSans-Bold.woff') format('woff'),
        url('fonts/hinted-MerriweatherSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('fonts/hinted-WorkSans-ExtraLight.eot');
    src: local('Work Sans ExtraLight'), local('WorkSans-ExtraLight'),
        url('fonts/hinted-WorkSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-WorkSans-ExtraLight.woff') format('woff'),
        url('fonts/hinted-WorkSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/hinted-MerriweatherSans-LightItalic.eot');
    src: local('Merriweather Sans Light Italic'), local('MerriweatherSans-LightItalic'),
        url('fonts/hinted-MerriweatherSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-MerriweatherSans-LightItalic.woff') format('woff'),
        url('fonts/hinted-MerriweatherSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: url('fonts/hinted-WorkSans-Bold.eot');
    src: local('Work Sans Bold'), local('WorkSans-Bold'),
        url('fonts/hinted-WorkSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-WorkSans-Bold.woff') format('woff'),
        url('fonts/hinted-WorkSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/hinted-MerriweatherSans-ExtraBold.eot');
    src: local('Merriweather Sans ExtraBold'), local('MerriweatherSans-ExtraBold'),
        url('fonts/hinted-MerriweatherSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-MerriweatherSans-ExtraBold.woff') format('woff'),
        url('fonts/hinted-MerriweatherSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('fonts/hinted-WorkSans-SemiBold.eot');
    src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'),
        url('fonts/hinted-WorkSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-WorkSans-SemiBold.woff') format('woff'),
        url('fonts/hinted-WorkSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/hinted-MerriweatherSans-ExtraBoldItalic.eot');
    src: local('Merriweather Sans ExtraBold Italic'), local('MerriweatherSans-ExtraBoldItalic'),
        url('fonts/hinted-MerriweatherSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-MerriweatherSans-ExtraBoldItalic.woff') format('woff'),
        url('fonts/hinted-MerriweatherSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Work Sans';
    src: url('fonts/hinted-WorkSans-Thin.eot');
    src: local('Work Sans Thin'), local('WorkSans-Thin'),
        url('fonts/hinted-WorkSans-Thin.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-WorkSans-Thin.woff') format('woff'),
        url('fonts/hinted-WorkSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('fonts/hinted-WorkSans-Light.eot');
    src: local('Work Sans Light'), local('WorkSans-Light'),
        url('fonts/hinted-WorkSans-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-WorkSans-Light.woff') format('woff'),
        url('fonts/hinted-WorkSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('fonts/hinted-MerriweatherSans-Light.eot');
    src: local('Merriweather Sans Light'), local('MerriweatherSans-Light'),
        url('fonts/hinted-MerriweatherSans-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-MerriweatherSans-Light.woff') format('woff'),
        url('fonts/hinted-MerriweatherSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('fonts/hinted-WorkSans-ExtraBold.eot');
    src: local('Work Sans ExtraBold'), local('WorkSans-ExtraBold'),
        url('fonts/hinted-WorkSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-WorkSans-ExtraBold.woff') format('woff'),
        url('fonts/hinted-WorkSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('fonts/hinted-WorkSans-Regular.eot');
    src: local('Work Sans'), local('WorkSans-Regular'),
        url('fonts/hinted-WorkSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/hinted-WorkSans-Regular.woff') format('woff'),
        url('fonts/hinted-WorkSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

table { 
	margin-top: 2rem;
	margin-bottom: 4rem;
	width: 100%;

	border-collapse: collapse; 

  tr {
     background: @terceary-color;
  }

  tr:nth-of-type(odd) { 
      background: #eee; 
  }

  th { 
      background:@seventernary-color; 
      color: @terceary-color; 
      text-transform: uppercase;
      font-weight: bold; 
      font-size: 1.6rem;
  }

  td,
  th { 
      padding: .8rem 1rem; 
      border: 1px solid fade(@sixternary-color, 10); 
      text-align: left; 
  }

  td {
    font-size: 1.7rem;
  }
}



@media screen and (max-width: 767px) {

    table {
      border: 0;
    }

    table thead {
      display: none;
    }

    table tr {
      margin-bottom: 10px;
      display: block;
      border-bottom: 2px solid #ddd;
    }

    table tr:nth-of-type(odd) { 
      background: #eee; 
    }

    table td {
      display: block;
      text-align: right;
      font-size: 13px;
      border-bottom: 1px dotted #ccc;
    }

    table td:last-child {
      border-bottom: 0;
    }

    table td:before {
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: bold;
    }
}